import { defineStore } from 'pinia';

export const useConsumerStore = defineStore('consumerStore', {
  state: () => ({
    uuid: '',
    action: '',
    connection_id: '',
  }),
  actions: {
    saveSubscribedData(data: any) {
      this.uuid = data['uuid'];
      this.action = data['action'];
      this.connection_id = data['connection_id'];
    },
  },
  persist: {
    storage: sessionStorage
  }
});
