<template>
  <section v-if="isRequestLoading" class="loading-state" />
  <section v-else-if="
    !isRequestLoading &&
    addAdsToPortfolios(portfolios, ads, currentAccount).length < 1
  ">
    <header>
      <h1 v-if="!isUserLoggedOut" class="font-wht">You have no Style yet!</h1>
      <h1 v-else class="font-wht">
        There is no style for the current slideshow!
      </h1>
    </header>
    <nav>
      <router-link v-if="currentAccount?.attributes?.account_id && !isUserLoggedOut" :to="{
        name: routesNames.addOrEditStyle,
        params: {
          account_id: currentAccount.attributes.account_id,
        },
      }">
        <IButton :tab-index="1" class="btn btn-primary"> Add a Style </IButton>
      </router-link>
    </nav>
  </section>
  <section v-else>
    <swiper :style="styleObject" :loop="true" :space-between="10" :navigation="true" :centered-slides="true"
      :autoplay="autoPlaySettings()" :modules="modules" class="mySwiper2" component="slideshow"
      :thumbs="{ swiper: thumbnailSwiper }" @swiper="onSwiper">
      <swiper-slide v-for="portfolio in addAdsToPortfolios(portfolios, ads, currentAccount)" :key="portfolio.id"
        :data-type="portfolio.type">
        <template v-if="
          portfolio.attributes.payload.length &&
          getFileExtension(portfolio.attributes.payload[0].filename).type ===
          'video'
        ">
          <c-video class="slide-img" :payload="portfolio.attributes.payload" :data-e2e="'slideshow-video'"
            @video-is-ready="handleImageIsReady"
             />
        </template>
        <template v-else-if="
          portfolio.attributes.payload.length &&
          portfolio.attributes.payload[0].slideshow_16x9 &&
          getFileExtension(portfolio.attributes.payload[0].filename).type ===
          'img'
        ">
          <c-image-slides :image-url="portfolio.attributes.payload[0].slideshow_16x9"
            :alt="portfolio.attributes.payload[0].filename" :portfolio-id="portfolio.id?.toString()"
            :data-e2e="'slideshow-image'" @image-is-ready="handleImageIsReady"
            @image-cannot-be-loaded="handleImageCannotBeLoaded(portfolio)" />
        </template>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script lang="ts">
import { A11y, Autoplay, Navigation, Thumbs } from 'swiper'
import type { Swiper } from 'swiper/types'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/vue'
import { PropType, ref } from 'vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import { CImageSlides, CVideo, Button as IButton } from '@/components'
import { getExtension, getIDFromString, slideShowImageKey } from '@/helpers'
import { routesNames } from '@/router'
import { useCableStore } from '@/store/cableStore'
import { useConsumerStore } from '@/store/consumerStore'
import { usePortfoliosSlideshowStore } from '@/store/portfolios/portfolios_slideshow'
import { useRoute } from 'vue-router'
import { useScreen } from 'vue-screen'
import type { AccountType, PortfolioPayloadType, PortfolioType } from '../types/index'

export default {
  components: {
    Swiper: SwiperComponent,
    SwiperSlide,
    IButton,
    CImageSlides,
    CVideo,
  },
  props: {
    portfolios: {
      type: Array<PortfolioType>,
      default: () => [],
    },
    ads: {
      type: Array<PortfolioType>,
      default: () => [],
    },
    isUserLoggedOut: {
      type: Boolean,
      default: false,
    },
    isRequestLoading: {
      type: Boolean,
      default: true,
    },
    slideShowWidth: {
      type: Number,
      default: 0,
    },
    slideShowHeight: {
      type: Number,
      default: 0,
    },
    subscription: {
      type: Object,
      default: null,
    },
    currentAccount: {
      type: Object as PropType<AccountType>,
      default: (): AccountType => ({
        id: '',
        type: '',
        attributes: {
          avatar: '',
          first_name: '',
          last_name: '',
          company: '',
          job_title: '',
          city: '',
          about_me: '',
          price_min: 0,
          price_max: 0,
          zip_code: 0,
          tags: [],
          abilities: [],
          invited_user_count: 0,
          state: '',
          fullname: '',
          account_id: '',
          status: 0,
          portfolio_count: 0,
          email: '',
          facebook: '',
          whatsapp: '',
          telegram: '',
          address: '',
          domain: '',
          telephone: '',
          instagram: '',
          referral_code: '',
          booking_link: '',
        },
      }),
    },
  },
  setup(props, { emit }) {
    const thumbnailSwiper = ref()
    const isImageLoaded = ref(false)
    const swiperInstance = ref<Swiper | null>(null)

    const setThumbnailSwiper = (swiper: unknown): void => {
      thumbnailSwiper.value = swiper
    }

    const addAdsToPortfolios = (
      portfolios: Array<PortfolioType>,
      ads: Array<PortfolioType>,
      currentAccount: AccountType
    ) => {
      const result: PortfolioType[] = []
      const totalLength = portfolios.length + ads.length
      let portfolioIndex = 0
      let adIndex = 0

      const abilities = currentAccount.attributes?.abilities || []
      const hasGoldOrDiamond =
        abilities.includes('GOLD') || abilities.includes('DIAMOND')

      const filteredAds = ads.filter((ad) => ad.attributes.payload.length > 0)

      for (let i = 0;i < totalLength;i++) {
        if (
          !hasGoldOrDiamond &&
          i % 4 === 3 &&
          adIndex < filteredAds.length
        ) {
          result.push(filteredAds[adIndex])
          adIndex++
        } else if (portfolioIndex < portfolios.length) {
          result.push(portfolios[portfolioIndex])
          portfolioIndex++
        }
      }
      // Add any remaining ads to the end
      if (!hasGoldOrDiamond) {
        while (adIndex < filteredAds.length) {
          result.push(filteredAds[adIndex])
          adIndex++
        }
      }

      if (result.length === 0) {
        emit('portfolioStyleIsEmpty')
      }

      return result
    }

    const handleImageIsReady = (isReady: boolean): void => {
      isImageLoaded.value = isReady
      if (swiperInstance.value?.autoplay) {
        isReady
          ? swiperInstance.value.autoplay.start()
          : swiperInstance.value.autoplay.pause()
      }
    }

    const onSwiper = (swiper: Swiper) => {
      swiperInstance.value = swiper
    }

    const autoPlaySettings = () =>
      isImageLoaded.value
        ? { delay: 10000, disableOnInteraction: false }
        : false

    return {
      addAdsToPortfolios,
      Thumbs,
      autoPlaySettings,
      thumbnailSwiper,
      setThumbnailSwiper,
      slideShowImageKey,
      handleImageIsReady,
      onSwiper,
      isImageLoaded,
      routesNames,
      styleObject: {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      },
      modules: [Navigation, Thumbs, A11y, Autoplay],
    }
  },
  methods: {
    getFileExtension(file: string | File) {
      return getExtension(file)
    },
    handleImageCannotBeLoaded(portfolio: PortfolioType) {
      portfolio.attributes.payload[0] = {} as PortfolioPayloadType
    },
    handleVideoCannotBeLoaded(portfolio: PortfolioType) {
      portfolio.attributes.payload = [] as PortfolioPayloadType[]
    },
  },
}
</script>

<style lang="scss">
.is-ready {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
}

.is-loader {
  margin: 2px;
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #fff 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

.slideshow {
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .mySwiper2 {
    /* Main Slider */
    // height: calc(100vh - 130px - 150px);
    // width: 100%;
  }

  .swiper-thumbs {
    /* Image Caroussel */
    display: block;
    // height: 20%;
    box-sizing: border-box;
    padding: 2vh 0;

    .swiper-slide {
      width: 25%;
      height: 120px;
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  .swiper-slide {
    // border-radius: 1em;
    overflow: hidden;
    background-size: cover;
    background-position: center;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper:hover {

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      display: block;
    }
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev,
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    display: none;
  }
}
</style>
