<template>
  <div>
    <main>
      <h1>
        <span @click="updateSelectedSubTab(0)">Analytics </span>
        <span v-if="selectedSubTab === 1" class="text-nowrap">
          > {{ currentAccount().attributes.fullname || fullnames }}
        </span>
      </h1>
      <TabGroup :selected-index="selectedSubTab">
        <TabPanels class="mt-5">
          <TabPanel>
            <data-table :items="analytics()" :fields="fields">
              <template #cell(status)="{ value }">
                <span class="icon-container">
                  <div :class="value === 'online' ? 'status-online' : 'status-offline'
                    " />
                </span>
              </template>
              <template #link="{ item }">
                <img src="@/assets/btn-next.png" srcset="@/assets/btn-next@2x.png 2x" alt="View Profile"
                  @click.prevent="goToProfile(item.account_id)" />
              </template>
            </data-table>
          </TabPanel>
          <TabPanel>
            <AnalyticProfileDetails :analytics="analytics()" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  </div>
</template>

<script lang="ts">
import { DataTable } from '@/components'
import { dateShort, weekDayShort } from '@/helpers'
import type { AccountType } from '@/types'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { computed } from 'vue'
import {
  Component,
  Emit,
  Inject,
  Vue,
  Watch,
  toNative,
} from 'vue-facing-decorator'
import { useRoute, useRouter } from 'vue-router'
import { useSelectTabStore } from '../store/accounts/selectTabStore'
import AnalyticProfileDetails from './AnalyticProfileDetails.vue'
import { throttle } from 'lodash';

@Component({
  components: {
    DataTable,
    AnalyticProfileDetails,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
})
class Subscribers extends Vue {
  @Inject
  currentAccount!: () => AccountType
  @Inject
  analytics!:  () => any[]
  router = useRouter()
  route = useRoute()

  get fullnames() {
    const accountId = this.route.query.account_id as string
    return this.analytics().find((analytic: any) => analytic.account_id === accountId).fullname || ''
  }


  createAt(date: Date) {
    return dateShort(date)
  }

  selectedSubTab = computed(
    () => useSelectTabStore().getSelectedSubTab
  ) as unknown as number

  @Emit
  goToProfile(account_id: string) {
    return { account_id }
  }

  @Emit
  updateSelectedSubTab(index: number) {
    this.router.push({ query: undefined })
    return index
  }

  lastSigning(date: Date) {
    if (date) {
      return weekDayShort(date)
    }
  }

  @Watch('$route.name', { immediate: true, deep: true })
  updatePageTitle(name: string) {
    if (name === 'admin') {
      document.title = `Admin - Analytics`
    }
  }

  fields = [
    { key: 'status', label: 'status' },
    { key: 'first_name', label: 'First name' },
    { key: 'last_name', label: 'Last name' },
    { key: 'city', label: 'Location' },
    {
      key: 'runtime_today',
      label: 'Runtime today',
      sortable: true,
    },
    { key: 'runtime_total', label: 'Runtime total', sortable: true },
  ]

  mounted() {
    this.startUserOnlineCheck();
  }

  beforeUnmount() {
    clearInterval(this.userOnlineInterval);
  }

  userOnlineInterval: ReturnType<typeof setInterval> | undefined;

  startUserOnlineCheck() {
    const throttledCheck = throttle(() => {
      const onlineUsers = this.analytics().filter((analytic: any) => analytic.status === 'online');
      onlineUsers.forEach((user: any) => {
        this.emitOnlineUser(user.account_id);
      });
    }, 5 * 60 * 1000); // Throttle to 5 minutes

    this.userOnlineInterval = setInterval(() => {
      throttledCheck();
    }, 1 * 60 * 1000); // Check every minute, but throttled to 5 minutes
  }

  @Emit('OnlineUser')
  emitOnlineUser(account_id: string) {
    return account_id;
  }
}
export default toNative(Subscribers)
</script>

<style scoped>
.icon-container {
  width: 10px;
  height: 10px;
}

.status-online {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
}

.status-offline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(240, 14, 14);
  border: 2px solid rgb(240, 14, 14);
}
</style>
