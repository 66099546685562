import { defineStore } from 'pinia'

import ErrorMessage from '@/ErrorMessage'
import { AdminDataService as Admin } from '@/services/index'
import { state as initialState } from '@/store/state'
import { ApiResponse } from '@/types'
import Bugsnag from '@bugsnag/js'
import { pick } from 'lodash'
import { ActionTypes } from '../action-types'
import { useErrorStore } from '../errorStore'
const state = pick(initialState, [
  'portfolio_ads',
  'isRequestLoading',
  'isLoading',
  'status',
  'statusText',
  'message',
])

export const useAdStore = defineStore('adStore', {
  state: () => ({ ...state }),
  getters: {},

  actions: {
    async [ActionTypes.GET_PORTFOLIO_ADS]() {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolio_ads = Admin.getPortfolioAds()

        resolve(portfolio_ads)
      })

      try {
        const { data, status, statusText } = await promise

        this.portfolio_ads = data["data"]
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
        if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
          const errorMessage = new ErrorMessage(
            ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS,
            error
          )
          Bugsnag.notify(errorMessage)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.DELETE_SLIDESHOW_ADS](adId: string) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const portfolio_ads = Admin.deleteAds(adId)

        resolve(portfolio_ads)
      })

      try {
        const { data, status, statusText } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
        if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
          const errorMessage = new ErrorMessage(
            ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS,
            error
          )
          Bugsnag.notify(errorMessage)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },

    async [ActionTypes.CREATE_SLIDESHOW_ADS]({
      ad_data,
    }: {
      ad_data: FormData
    }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise(resolve => {
        const admin = Admin.createSlideshowAds(ad_data)

        resolve(admin)
      })

      try {
        const { data, status, statusText } = await promise

        this.status = Number(status)
        this.statusText = statusText
        this.message = 'Ads has been created'
      } catch (error) {
        if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
          const errorMessage = new ErrorMessage(
            ActionTypes.GET_SUBSCRIBERS,
            error
          )
          Bugsnag.notify(errorMessage)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
  },
  persist: true,
})
