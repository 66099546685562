<script setup lang="ts">
  import { logoY } from '@/helpers';
import { useImage } from '@vueuse/core';
import { onMounted, onUnmounted, ref, watch } from 'vue';

  const props = defineProps<Props>()
  const emit = defineEmits(['videoIsReady','videoCannotBeLoaded']);

  const copyPayload = props.payload ?? []
  // fot test purpose adding dummy array
  const reversedPayload = props.videoUrl
    ? [{ file: '', content_type: '' }]
    : copyPayload.reverse()
  const link =
    typeof props.videoUrl === 'string'
      ? props.videoUrl
      : (reversedPayload[0].file as string)
  const imageOptions = ref<{ src: string }>({ src: link })
  const { isLoading, error } = useImage(imageOptions, { delay: 1000 })
  const updateVideoTypeOnQuicktime = (type: string) => {
    return props.contentType === 'video/quicktime' ? 'video/mp4' : type
  }

  const videoContainer = ref()

  const observer = new IntersectionObserver(
    entries => {
      if (!videoContainer.value) return

      if (entries[0].isIntersecting) {
        videoContainer.value.querySelector('video')?.play()
      } else {
        const playPromise = videoContainer.value.querySelector('video')?.play()
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              // Show playing UI.
              // We can now safely pause video...
              videoContainer.value.querySelector('video')?.pause()
            })
            .catch((error: Error) => {
              console.error('Error playing video:', error)
            })
        }
      }
    },
    {
      threshold: 1.0, // Adjust to your desired threshold
    }
  )
  interface VideoPayload {
    file: string
    content_type: string
    image_key?: string
  }

  interface Props {
    payload?: VideoPayload[]
    videoUrl?: string
    contentType?: string
    dataE2e?: string
  }

  watch(isLoading,  (newValue) => {
    if (!newValue) {
      emit('videoIsReady', true);
    }
  })

  onMounted(() => {
    if (videoContainer.value) {
      observer.observe(videoContainer.value)
    }
  })

  onUnmounted(() => {
    if (videoContainer.value) {
      observer.unobserve(videoContainer.value)
    }
  })

const isAnErrorValue = ref(false)

// Expose the error and isAnErrorValue to the parent component for testing purposes
defineExpose({ error,isAnErrorValue })

watch(error, (newError) => {
  isAnErrorValue.value = newError !== undefined
  if (isAnErrorValue.value) {
    emit('videoCannotBeLoaded', isAnErrorValue.value)
  }
})
</script>

<template>
  <div ref="videoContainer">
    <div
      v-if="isLoading"
      class="is-ready"
    >
      <img
        :src="logoY"
        :srcset="logoY"
        alt="loader"
        class="loader-logo"
      >
      <div class="is-loader" />
    </div>

    <video
      v-else
      id="video"
      width="100%"
      autoplay
      muted
      playsinline
      loop
    >
      <source
        v-if="videoUrl && contentType"
        :src="videoUrl"
        :type="contentType"
        :data-e2e="dataE2e"
      >
      <template
        v-for="rPayload in reversedPayload"
        v-else
        :key="rPayload.image_key"
      >
        <source
          :src="rPayload.file"
          :type="updateVideoTypeOnQuicktime(rPayload.content_type)"
        >
      </template>
      <track
        label="English"
        kind="captions"
        srclang="en"
        src=""
        default
      >
    </video>
  </div>
</template>

<style lang="scss" scoped>
  .text {
    margin-bottom: 0.7rem;
    font-size: 2rem;
    margin-left: 0.5rem;
  }

  .loader-logo,
  .slideshow .swiper-slide .loader-logo {
    width: unset;
    height: 40%;
  }

  .is-ready {
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    background-color: #0000;
    margin: auto;
  }

  .is-loader {
    width: 40px;

    margin-left: -4px;
    margin-bottom: -40%;
    aspect-ratio: 4;
    background: url('/src/assets/logo-dot.svg') 0 / calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 900ms steps(4) infinite;
  }

  @keyframes l1 {
    to {
      clip-path: inset(0 -34% 0 0);
    }
  }
</style>
